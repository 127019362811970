@font-face {
  font-family: 'Questrial';
  src: url('/public/fonts/questrial-font.otf');
}

body {
  margin: 0;
  font-family: 'Questrial', -apple-system, BlinkMacSystemFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.block {
  display: block;
  width: 100%;
  border: none;
  padding: 14px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  border-radius: 8px;
}